import axios from "axios";
import {
  checkInApiProps,
  checkInsServerApi,
  listBookingServerApi,
  rsvnNorAndsVnSeqNoProps,
} from "./sanha";

const baseURL = process.env.REACT_APP_IMGATE_BACKEND_BASEURL;
const apiKey =
  "MjFlMGE2NDUzOTE4MTVjMjM0YzNhMWVjMmJhZDFkMzYxZjZjODEwYzpkNWE0NzkzNjMyOWMxZWZmMDZjMzE4MDA3MjhlNDA3MjFlM2JlZWE1";
const hotelId = "75b6cd602b1740f0110d520bffe6446cdaffb352";
const langTypeCode = "KOR";

// config
const request = axios.create({
  baseURL,
});

const headers = (header: any) => {
  return {
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
      Charset: "utf-8",
      ...(header && header),
    },
  };
};

interface PmsBookingApi {
  created_at: string;
  exchangekey: string;
  folio_no: string;
  hotel_id: string;
  pincode: string;
  reservation_no: string;
  updated_at: string;
}

type ServerState<A> = {
  code: string;
  message: string;
  data: A;
  pms_booking: PmsBookingApi;
};

export interface kakaoUserDataProps {
  hotel_id: string;
  account_email?: string;
  name?: string;
  gender?: string;
  age_range?: string;
  birthday?: string;
  birthyear?: string;
  phone_number?: string;
}

export const kakaoUserData = ({
  hotel_id,
  account_email,
  name,
  gender,
  age_range,
  birthday,
  birthyear,
  phone_number,
}: kakaoUserDataProps): Promise<ServerState<any>> => {
  const data = {
    hotel_id,
    account_email,
    name,
    gender,
    age_range,
    birthday,
    birthyear,
    phone_number,
  };
  return request.post("/multifamily/kakao_user_data", data, headers({}));
};

export const listBooking = async ({
  rsvnNo,
  rsvnSeqNo = "1",
}: rsvnNorAndsVnSeqNoProps): Promise<ServerState<listBookingServerApi>> => {
  return request
    .get(`/v2/multifamily/pms_bookings/${hotelId}/${rsvnNo}/${rsvnSeqNo}`, {
      ...headers({}),
    })
    .then((res) => res.data);
};

//체크인 처리
export const checkIn = ({
  rsvnNo,
  rsvnSeqNo = "1",
  roomNo,
  privacyPolicy = "Y",
}: checkInApiProps): Promise<ServerState<checkInsServerApi>> => {
  return request
    .post(
      `v2/multifamily/pms_bookings/${hotelId}/checkins`,
      {
        rsvn_no: rsvnNo,
        rsvn_seq_no: rsvnSeqNo,
        room_no: roomNo,
        privacy_policy: privacyPolicy,
        lang_type_code: langTypeCode,
      },
      headers({})
    )
    .then((res) => res.data);
};
