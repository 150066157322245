import React, { Fragment } from 'react';
import styled from 'styled-components';

const ContentWrap = styled.section`
  margin: 0 30px;
  padding: 0 0 78px;
`;

const CustomContent = ({
  ContentBody,
  customStyle,
}:any) => {
  return (
    <Fragment>
      <ContentWrap style={customStyle}>
        {ContentBody}
      </ContentWrap>
    </Fragment>
  );
};

export default CustomContent;