import React, { Fragment } from 'react';
import styled from "styled-components";


const HeaderWrap = styled.header`
display: flex;
position: relative;
margin: 0 16px 0 20px;
background-color: #fff;
`;

const CustomHeader = () => {
  return (
    <Fragment>
      <HeaderWrap>
      </HeaderWrap>
    </Fragment>
  );
};

export default CustomHeader;