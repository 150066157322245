import * as types from '../constants';

interface initState {
  queryStringParams: any,
  bookingItem: any,
  roomInfo: any,
  userInfo: any,
  paymentInfo: any,
  privacyPolicy: string,
}

const initialState:initState = {
  queryStringParams: {},
  bookingItem: {},
  roomInfo: {},
  userInfo: {},
  paymentInfo: {},
  privacyPolicy: '',
};

const BookingListReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case types.SET_BOOKING_QUERY_STRING_PARAMS:
      return {
        ...state,
        queryStringParams: action.payload,
      };
    case types.SET_BOOKING_ITEM:
      return {
        ...state,
        bookingItem: action.payload,
      };
    case types.SET_ROOM_INFO:
      return {
        ...state,
        roomInfo: action.payload,
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case types.SET_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.payload,
      };
    case types.SET_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicy: action.payload,
      };
    default:
      return state;
  }
};

export default BookingListReducer;