import * as types from '../constants';

export const setBookingQueryStringParams = (queryStringParams:any) => ({
  type: types.SET_BOOKING_QUERY_STRING_PARAMS,
  payload: queryStringParams
});

export const setBookingItem = (bookingItem:any) => ({
  type: types.SET_BOOKING_ITEM,
  payload: bookingItem
});

export const setRoomInfo = (roomInfo:any) => ({
  type: types.SET_ROOM_INFO,
  payload: roomInfo
});

export const setUserInfo = (userInfo:any) => ({
  type: types.SET_USER_INFO,
  payload: userInfo
});

export const setPaymentInfo = (paymentInfo:any) => ({
  type: types.SET_PAYMENT_INFO,
  payload: paymentInfo
});

export const setPrivacyPolicy = (privacyPolicy:string) => ({
  type: types.SET_PRIVACY_POLICY,
  payload: privacyPolicy
});