import ReactGA from 'react-ga4';

export const initialize = ({measurementId}:{measurementId?:string}) => {
  
  return measurementId? ReactGA.initialize(measurementId) : measurementId;
};

interface customeEventProps {
  category: string,
  action: any,
  label?: string,
}

export const customEvent = ({category, action, label=''}:customeEventProps) => {
  return ReactGA.event({category, action, label});
};
