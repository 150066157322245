import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_KICC_BACKEND_BASEURL;

// config
const request = axios.create({
  baseURL,
});

const headers = (header:any) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      ...header && header,
    }
  };
};

export const registTransaction = (data:any) => {
  return request.post('/api/trades/webpay',
    data,
    headers({}),
  );
};

export const requestApproval = (data:any) => {
  return request.post('/api/trades/approval',
    data,
    headers({}),
  );
};
