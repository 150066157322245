import React, { useEffect } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import * as GoogleAnalytics from '../lib/google-analytics';
import FrontInfo from '../components/views/FrontInfo';

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const FrontInfoContainer = (props:Props) => {
  
  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_front_info',
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <FrontInfo
          />
        )}
      />
    </div>
  );
};

export default FrontInfoContainer;