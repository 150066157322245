import React, { Fragment, useEffect } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import Modal from '../Modal';
import styled from 'styled-components';

interface ModalLocationSerivceGuideIOSProps {
  isOpen: boolean,
  onClose: () => void,
}

const LocationServiceWrap = styled.div`
.location-service-title {
  display: block;
  height: 24px;
  margin: 14px 0 0;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000;
}
.location-service-icon {
  font-size: 90px;
  color: #5f5f5f;
  text-align: center;
}
  @media (max-height: 420px) {
    .location-service-icon {
      font-size: 100px;
    }
  }
  @media (max-height: 370px) {
    .location-service-icon {
      font-size: 80px;
    }
  }
.location-service-description {
  display: block;
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  word-break: keep-all;
  color: #666;
}
  @media (max-width: 320px) {
    .location-service-description {
      font-size: 13px;
    }
  }

button.confirm-button {
  width: 100%;
  height: 48px;
  padding: 0;
  background-color: #333;
  border: unset;
  border-radius: 8px;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: #fff;
}
  button.confirm-button:disabled {
    background-color: rgb(203,204,205);
  }
`;

const ModalLocationSerivceGuideIOS = ({
  isOpen,
  onClose,
}:ModalLocationSerivceGuideIOSProps) => {
  const confirm = async () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_ios_guide_confirm',
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_location_service_ios_guide',
    });
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        size='medium'
        Content={(
          <LocationServiceWrap>
            <div className='w-100 mb-15 pt-30'>
              <div className='mb-15'>1. 위치 기반 설정이 안 되어 있는 경우</div>
              <img className='w-100' src='../../assets/images/ios-location-service-guide.png' alt='' />
            </div>
            <div>2.IOS 16.4.1 버전 위치 기반 설정이 적용되지 않는 경우</div>
            <ul style={{fontSize: '0.875rem', paddingLeft: '20px', fontWeight: 'normal'}}>
              <li>위치 설정이 안될 경우 IOS Version 16.5로 업데이트를 진행 해 주세요.</li>
              <li style={{marginTop: '10px'}}>또는 카카오 IN WEB 하단 - 다른 브라우저 열기를 통해 크롬 브라우저를 사용해 주세요</li>
            </ul>
            <button className='confirm-button h-40 mb-14' onClick={confirm}>
              확인
            </button>
          </LocationServiceWrap>
        )}
      />
    </Fragment>
  );
};

export default ModalLocationSerivceGuideIOS;