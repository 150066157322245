import React, { Fragment } from 'react';
import Modal from './Modal';
import styled from 'styled-components';

interface PaymentErrorProps {
  isOpen: boolean,
  message: string,
  onClose: () => void,
}

const PaymentErrorWrap = styled.div`
.error-modal-message {
  display: block;
  margin: 14px 0 10px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: center;
  color: #ff4129;
}
.error-modal-sub-message {
  display: block;
  margin: 0 0 14px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  word-break: keep-all;
  color: #666;
}
button.confirm-button {
  width: 100%;
  height: 48px;
  padding: 0;
  background-color: #333;
  border: unset;
  border-radius: 8px;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: #fff;
}
  button.confirm-button:disabled {
    background-color: rgb(203,204,205);
  }
`;

const PaymentError = ({
  isOpen,
  message,
  onClose,
}:PaymentErrorProps) => {
  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <PaymentErrorWrap>
            <span className='error-modal-message'>
              결제 요청에 실패 하였습니다.
            </span>
            <span className='error-modal-sub-message'>
              {message}
            </span>
            <button className='confirm-button h-40 mb-14' onClick={onClose}>
              확인
            </button>
          </PaymentErrorWrap>
        )}
      />
    </Fragment>
  );
};

export default PaymentError;