import React, { Fragment } from 'react';
import Header from '../Header';
import Content from '../Content';
// import Footer from '../Footer';

const MainLayout = ({
  customStyle,
  ContentBody,
}:any) => {
  return (
    <Fragment>
      <Header />
      <Content customStyle={customStyle} ContentBody={ContentBody}/>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default MainLayout;