import "./assets/css/index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./stores";
import MainStyled from "./Main.styled";
import CheckInStyled from "./CheckIn.styled";
import RoomStyled from "./Room.styled";
import * as GoogleAnalytics from "./lib/google-analytics";

const { Kakao } = window as any;

Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
Kakao.isInitialized();

const isMobile = () => {
  const pc = "win16|win32|win64|mac";
  if (window.navigator.platform) {
    if (pc.indexOf(window.navigator.platform.toLowerCase()) < 0) return true;
    else return false;
  } else return true;
};

GoogleAnalytics.initialize({
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            {isMobile() ? (
              <>
                <Route path="/" element={<MainStyled.BookingList />} />
                {/* 이용동의 약관 */}
                <Route
                  path="/terms_of_use"
                  element={<MainStyled.TermsOfUseListContainer />}
                />
                {/* 체크인 관련 (카카오 본인인증) */}
                <Route path="/checkin" element={<CheckInStyled.CheckIn />} />
                {/* 외부 결제 */}
                <Route
                  path="/checkin/payment"
                  element={<CheckInStyled.CheckInPayment />}
                />
                {/* 결제 완료 후 산하에 보낼 때, 체크인 완료, 혹은 객실 선택으로 이동 */}
                <Route
                  path="/checkin/payment/response"
                  element={<CheckInStyled.CheckInPaymentResponse />}
                />
                {/* 체크 아웃 부분 */}
                <Route
                  path="/checkout/payment"
                  element={<CheckInStyled.CheckOutPayment />}
                />
                <Route
                  path="/checkout/payment/response"
                  element={<CheckInStyled.CheckOutPaymentResponse />}
                />
                <Route
                  path="/checkout/payment/success"
                  element={<CheckInStyled.CheckOutPaymentSuccess />}
                />
                {/* 객실 선태 화면 */}
                <Route path="/room" element={<RoomStyled.RoomSelect />} />
                {/* caclPayCode 5,6을 제외하고는 안내 페이지 호출 */}
                <Route
                  path="/frontInfo"
                  element={<CheckInStyled.FrontInfo />}
                />
                <Route path="*" element={<MainStyled.Page404 />} />
              </>
            ) : (
              <>
                <Route path="*" element={<MainStyled.PagePC />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
