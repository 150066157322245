import React, { useEffect } from 'react';
import _ from 'lodash';
import Modal from '../Modal';
import styled from 'styled-components';
import * as GoogleAnalytics from '../../lib/google-analytics';

interface CheckOutProps {
  isOpen: boolean,
  onClose: () => void,
  checkOut: () => void,
}

const CheckOutWrap = styled.div`
.check-out-title {
  display: block;
  height: 24px;
  margin: 14px 0 8px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000;
}
.check-out-description {
  display: block;
  height: 20px;
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  color: #666;
}
.check-out-notice-container {
  padding: 16px 25px 16px;
  margin: 0 0 8px;
  border-radius: 8px;
  background-color: #f9f9f9;
}
  .check-out-notice-container .check-out-notice-title-container {
    display: flex;
    margin: 0 0 2px;
    justify-content: center;
  }
    .check-out-notice-container .check-out-notice-title-container .check-out-notice-title {
      height: 14px;
      font-size: 12px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.4px;
      color: #666;
    }
  .check-out-notice-container .check-out-notice-description {
    display: block;
    font-size: 11px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.2px;
    text-align: center;
    color: #666;
  }
.check-out-button-container {
  display: flex;
}
  .check-out-button-container .cancel-button,
  .check-out-button-container .check-out-button {
    width: 100%;
    height: 42px;
  }
    .check-out-button-container .cancel-button .cancel-button-text,
    .check-out-button-container .check-out-button .check-out-button-text {
      display: block;
      height: 42px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: -0.4px;
      color: #b6b6b6;
      text-align: center;
    }
      .check-out-button-container .check-out-button .check-out-button-text.c-red {
        color: #ff4129;
      }
`;

const CheckOut = ({
  isOpen,
  onClose,
  checkOut,
}:CheckOutProps) => {
  const debounceCheckOut = _.debounce(() => checkOut(), 800, { maxWait: 1000 });

  const onClickCheckOut = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_ok',
    });
    debounceCheckOut();
  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_cancel',
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkout_confirm',
    });
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      isClosable={false}
      onClose={onCancel}
      Content={(
        <CheckOutWrap>
          <span className='check-out-title'>
            체크아웃 하시겠습니까?
          </span>
          <span className='check-out-description'>
            체크아웃 시 해당 객실에 재입장 불가합니다.
          </span>
          <div className='check-out-notice-container'>
            <div className='check-out-notice-title-container'>
              <img className='notice-icon mt-1 mr-4' src='../../assets/images/exclamation-mark.png' alt='' />
              <span className='check-out-notice-title'>
                추가 서비스 결제
              </span>
            </div>
            <span className='check-out-notice-description'>
              서비스 추가 비용이 있을 경우<br/>
              결제 후 체크아웃이 완료됩니다.
            </span>
          </div>
          <div className='check-out-button-container'>
            <div className='cancel-button' onClick={onCancel}>
              <span className='cancel-button-text'>
                아니요
              </span>
            </div>
            <div className='check-out-button' onClick={onClickCheckOut}>
              <span className='check-out-button-text c-red'>
                체크아웃 하기
              </span>
            </div>
          </div>
        </CheckOutWrap>
      )}
    />
  );
};

export default CheckOut;