import React, { Fragment } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

interface SpinnerProps {
  isLoading: boolean
}
const SpinnerWrap = styled.div`
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: rgba(0, 0, 0, 0.6);
z-index: 1999;

.spinner-bar {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 25px);
  z-index: 2000;
}

.spinner-text {
  position: fixed;
  top: 50%;
  width: 100%;
  color: #8fb0cf;
  font-size: 16px;
  z-index: 2000;
  display: block;
  text-align: center;
}
`;

const Spinner = ({
  isLoading,
}:SpinnerProps) => {
  return (
    <Fragment>
      <SpinnerWrap hidden={!isLoading}>
        <ClipLoader className='spinner-bar' color="#8fb0cf" size={50} />
        <span className='spinner-text'>
          잠시만 기다려 주세요...
        </span>
      </SpinnerWrap>
    </Fragment>
  );
};

export default Spinner;