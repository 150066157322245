import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import MainLayout from "../components/layouts/MainLayout";
import Payment from "../components/views/Payment";
import ModalError from "../components/ModalError";
import * as kiccApi from "../api/kicc";
import * as sanhaApi from "../api/sanha";
import * as GoogleAnalytics from "../lib/google-analytics";
import Spinner from "../components/Spinner";
import { RootState } from "../stores/reducers";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInPaymentContainer = (props: Props) => {
  const navigation = useNavigate();
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem, paymentInfo, queryStringParams } = useSelector(
    (state: RootState) => state.bookingList
  );

  const [totalAmount, setTotalAmount] = useState(bookingItem.roomRate);
  const [isLoading, setIsLoading] = useState(false);

  const registTransaction = async () => {
    try {
      const { data: registedTransaction } = await kiccApi.registTransaction({
        mallId: process.env.REACT_APP_KICC_MALL_ID,
        payMethodTypeCode: "11",
        currency: "00",
        amount: totalAmount || 1000,
        clientTypeCode: "00",
        returnUrl: process.env.REACT_APP_KICC_CALLBACK_URL,
        deviceTypeCode: "mobile",
        shopOrderNo: bookingItem.rsvnNo,
        orderInfo: {
          goodsName: `[${bookingItem.roomTypeCode}] ${bookingItem.roomTypeDesc}`,
        },
      });
      return registedTransaction;
    } catch (error) {
      Sentry.captureMessage(
        `registTransaction_Error : ${error} (예약번호:${bookingItem.rsvnNo})`
      );
      throw error;
    }
  };

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
    setIsError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const confirmCheckInPayment = async () => {
    try {
      setIsLoading(true);
      //결제 여부 체크
      const confirmCheckInPaymentResponse = await sanhaApi.config();
      if (confirmCheckInPaymentResponse.code !== "0000") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_confirm_checkin_payment",
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.message}`,
        });

        Sentry.captureMessage(
          `confirmCheckInPayment_Error : ${confirmCheckInPaymentResponse.code}: ${confirmCheckInPaymentResponse.message} (예약번호:${bookingItem.rsvnNo})`
        );
        setModalErrorMessage("예약 결제 여부 확인에 실패 하였습니다.");
        throw new Error(
          `${confirmCheckInPaymentResponse.code}, ${confirmCheckInPaymentResponse.message}`
        );

        // pg 연동 여부 === "N" 이면 체크인 처리(객실 선택 있으면 객실 선택으로)
      } else if (confirmCheckInPaymentResponse.data.pgPaymentYn === "N") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_confirm_checkin_payment",
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.data.pgPaymentYn}`,
        });
        navigation("/room");

        // 체크인 결제 여부 === "N" 이면 체크인 처리(객실 선택 있으면 객실 선택으로)
      } else if (confirmCheckInPaymentResponse.data.depositUseYn === "N") {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_confirm_checkin_payment",
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.data.pgPaymentYn}`,
        });
        navigation("/room");
      } else {
        const confirmCheckInAmountResponse =
          await sanhaApi.confirmCheckInAmount({
            rsvnNo: bookingItem.rsvnNo,
            rsvnSeqNo: "1",
          });
        if (confirmCheckInAmountResponse.code !== "0000") {
          GoogleAnalytics.customEvent({
            category: "api_response",
            action: "api_response_fail_confirm_checkin_amount",
            label: `${confirmCheckInAmountResponse.code}_${confirmCheckInAmountResponse.message}`,
          });

          Sentry.captureMessage(
            `confirmCheckInPayment_Amount_Error : 예약 결제 금액 확인에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`
          );
          setModalErrorMessage("예약 결제 금액 확인에 실패 하였습니다.");
          throw new Error(
            `${confirmCheckInAmountResponse.code}, ${confirmCheckInAmountResponse.message}`
          );
        } else if (!confirmCheckInAmountResponse.data.totalAmount) {
          GoogleAnalytics.customEvent({
            category: "api_response",
            action: "api_response_ok_confirm_checkin_amount",
            label: `${confirmCheckInAmountResponse.code}_${confirmCheckInAmountResponse.data.totalAmount}`,
          });
          navigation("/room");
        } else {
          setTotalAmount(confirmCheckInAmountResponse.data.totalAmount);
        }
      }
    } catch (error: any) {
      Sentry.captureMessage(
        `confirmCheckInPayment_response_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      paymentInfo &&
      paymentInfo.isPaid &&
      paymentInfo.rsvnNo === bookingItem.rsvnNo
    )
      navigation("/room"); //결제 이력이 있을 경우 바로 객실 선택으로
    else if (
      queryStringParams &&
      queryStringParams.responseCode === "0000" &&
      queryStringParams.shopOrderNo === bookingItem.rsvnNo
    ) {
      navigation("/room");
    } else {
      confirmCheckInPayment(); //결제 금액 체크
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_checkin_payment",
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
    });
  }, [bookingItem.guestName, bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={
          <Payment
            bookingItem={bookingItem}
            registTransactionAPI={registTransaction}
            amount={totalAmount}
            setModalErrorMessage={setModalErrorMessage}
            setModalErrorSubMessage={setModalErrorSubMessage}
            openModalError={openModalError}
            isError={isError}
            errorMessage={modalErrorSubMessage}
          />
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default CheckInPaymentContainer;
