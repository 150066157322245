import { combineReducers } from 'redux';
import bookingList from './BookingListReducer';

const rootReducer = combineReducers({
  bookingList,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
