import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean,
  isClosable: boolean,
  onClose: () => void,
  title?: string,
  size?: string,
  Content: any,
}

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 998;

  .modal .modal-body {
    position: fixed;
    left: 50%;
    top: 50%;
    width: calc(100% - 40px);
    max-height: 90%;
    padding: 16px 20px;
    border-radius: 24px;
    box-shadow: 4px 4px 16px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: auto;
  }
    .modal .modal-body.full {
      width: 100%;
      height: 100%;
      border-radius: unset;
      padding: 0;
    }
    .modal .modal-body.medium {
      height: 400px;
    }
    .modal .modal-body .modal-title-container {
      width: 100%;
      height: 52px;
      position: fixed;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      z-index: 999;
    }
      .modal .modal-body .modal-title-container .modal-title {
        display: block;
        height: 52px;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 52px;
        letter-spacing: -0.4px;
        text-align: center;
        color: #000;
      }
    .modal .modal-body .modal-content-container {
      height: 100%;
    }
    .modal .modal-body .close-button-container {
      position: fixed;
      left: 18px;
      top: 18px;
      z-index: 1000;
    }
      .modal .modal-body.full .close-button-container {
        left: 16px;
        top: 14px;
      }
img.close-button {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
`

const Modal = ({
  isOpen,
  isClosable,
  onClose,
  title,
  size,
  Content,
}:ModalProps) => {
  return (
    <ModalWrap hidden={!isOpen}>
      <div className='modal'>
        <div className={`modal-body ${size}`}>
          <div className='close-button-container' hidden={!isClosable} onClick={onClose}>
            <img className='close-button' src='../assets/images/close-button.png' alt='' />
          </div>
          <div className='modal-title-container' hidden={!title}>
            <span className='modal-title'>{title}</span>
          </div>
          <div className={`modal-content-container ${title ? 'mt-52':''}`}>
            {Content}
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};

export default Modal;