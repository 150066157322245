import React, { useEffect } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import CheckOutPaymentSuccess from '../components/views/CheckOutPaymentSuccess';
import * as GoogleAnalytics from '../lib/google-analytics';

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const CheckOutPaymentSuccessContainer = (props:Props) => {
  
  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkout_success',
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <CheckOutPaymentSuccess
          />
        )}
      />
    </div>
  );
};

export default CheckOutPaymentSuccessContainer;