import React, { Fragment } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';

interface PaymentProps {
  bookingItem: any,
  registTransactionAPI: any,
  amount: number,
  setModalErrorMessage: any,
  setModalErrorSubMessage: any,
  openModalError: any,
  isError: boolean,
  errorMessage: string,
}

const Payment = ({
  bookingItem,
  registTransactionAPI,
  amount,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
  isError,
  errorMessage,
}:PaymentProps) => {
  const registTransaction = async () => {
    try {
      GoogleAnalytics.customEvent({
        category: 'button_click',
        action: 'button_click_payment_request',
      });
      const registedTransaction = await registTransactionAPI();
      const approvalForm = document.getElementById('approvalForm')  as HTMLFormElement;
      approvalForm.resCd.value = registedTransaction.resCd;
      approvalForm.resMsg.value = registedTransaction.resMsg;
      approvalForm.action = registedTransaction.authPageUrl;
      approvalForm.submit();
    } catch (error:any) {
      setModalErrorMessage('결제 요청에 실패 하였습니다.');
      setModalErrorSubMessage(error.response ? `${error.response.data.code}, ${error.response.data.message}` : error.message);
      openModalError();
    }
  };

  const totalAmountFormatter = (totalAmount:number) => {
    return totalAmount ? totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : totalAmount;
  };

    return (
      <Fragment>
        <span className='check-in-payment-title'>
          결제하기
        </span>
        <div className='product-name-container'>
          <span>
            상품명
          </span>
          <span className='product-name-value'>
          [{bookingItem.roomTypeCode}] {bookingItem.roomTypeDesc}
          </span>
        </div>
        <div className='total-amount-container'>
          <span>
            총 금액
          </span>
          <span className='total-amount-value'>
            {`${totalAmountFormatter(amount)}원`}
          </span>
        </div>
        {isError?( 
        <div className='payment-error-message'>
          <span>에러가 발생했습니다. 결제 진행을 할 수 없습니다.</span>
          <div className='error-message'>({errorMessage})</div>
        </div>):(
          <button className='check-in-payment-button' onClick={registTransaction}>결제하기</button>
        )}
        
        <form id='approvalForm' name='approvalForm' method='post'>
          <input type='hidden' id='resCd' name='resCd' />
          <input type='hidden' id='resMsg' name='resMsg' />
        </form>
      </Fragment>
    );
};

export default Payment;